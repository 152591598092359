<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M18.13 7.75h-4.26V5.84l0 0c-.06-.55.34-1.04.89-1.1 .03-.01.06-.01.1-.01h3v-4h-4.33c-3.93 0-4.67 3-4.67 4.86v2.15h-3v4h3v11.5h5v-11.5h3.85Z"
    />
  </svg>
</template>
